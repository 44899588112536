<template>
  <div id="app" v-if="navbarArr.length > 0">
    <nav class="navbar">
      <div class="navbar-con">
        <img class="logo" src="@/assets/logo.jpg" alt="">
        <div class="navbar_content">
          <div class="item" :class="active == index ? 'active' : ''" v-for="(item, index) in navbarArr" :key="index" @click="goNextFn(item.to, index)">{{ item.name }}</div>
        </div>
      </div>
    </nav>
    <div class="main-con">
      <transition name="el-fade-in" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>


export default {
  name: 'App',
  data() {
    return {
      active: 0,
      navbarArr: []
    }
  },
  created() {
    console.log(1111)
  },
  mounted() {
    this.navbarArr = [
      { name: "首页", to: "/" },
      { name: "详情", to: "/info" },
    ]
  },
  methods: {
    goNextFn(to, index) {
      if(this.active != index) {
        this.active = index;
        this.$router.push(to);
      }
    }
  }
}
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
    display: none;
    width: 0 !important;
    height: 0 !important;
    -webkit-appearance: none;
    background: transparent;
}
#app {
  height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
}

.navbar {
    background-color: rgba(10, 124, 231, 0.5);
    /* 设置背景颜色为半透明黑色 */
    backdrop-filter: blur(10px);
    /* 添加高斯模糊效果，模糊半径可根据需要调整 */
}
.navbar-con {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 10px 0;
}


.navbar .logo {
  width: 70px;
  height: 70px;
  border-radius: 50px;
}

.navbar_content {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 100px;
}

.navbar_content .item {
  width: 120px;
  color: #fff;
  font-size: 24px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.navbar_content .item.active {
    color: #1890FF;
}

.main-con {
  flex: 1;
  overflow-y: auto;
}

</style>
