import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: '/',
      component: () => import('@/views/Home.vue'),
      meta: {
        showHeader: false,
        showFooter: false,
        title: '首页'
      }
    },
    {
      path: '/info',
      name: 'info',
      component: () => import('@/views/Info.vue'),
      meta: {
        showHeader: false,
        showFooter: false,
        title: '详情'
      }
    }
  ]
})


router.beforeEach((to, from, next) => {
  next();
})

export default router;